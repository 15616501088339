<template>
  <div class="nash">
    <TopTitle title="选择你的岗位"></TopTitle>
    <ul class="flex jc nash_top">
      <li v-for="(item, index) in imgs" :key="index">
        <el-avatar>
          <span class="title">{{ item.title }}</span>
          <img class="oImg" :src="item.oImg" />
        </el-avatar>
      </li>
    </ul>
    <TopTitle title="福利待遇"></TopTitle>
    <ul class="flex jc nash_conter">
      <li v-for="(item, index) in 6" :key="index">
        <div class="title">薪资福利</div>
        <div class="text">养老保险、医疗保险、失业保险、工伤保险和生育保险</div>
      </li>
    </ul>
  </div>
</template>

<script>
import TopTitle from '../components/public/TopTitle';
export default {
  name: 'nash',
  data() {
    return {
      imgs: [
        {
          title: '设计师',
          oImg: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg'
        },
        {
          title: '产品经理',
          oImg: 'https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png'
        },
        {
          title: '视频审核',
          oImg: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg'
        },
        {
          title: '程序员',
          oImg: 'https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png'
        },
        {
          title: '运营类',
          oImg: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg'
        },
        {
          title: '客服人员',
          oImg: 'https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png'
        }
      ]
    };
  },
  components: { TopTitle }
};
</script>
<style lang="scss">
.nash {
  padding: 34px 0 80px;
  &_top {
    margin: 50px 0 70px;
    & > li {
      margin: 0 12px;
    }
    .el-avatar {
      width: 230px;
      height: 230px;
      position: relative;
      .title {
        color: #000000;
        font-size: 24px;
        position: absolute;
        top: 44px;
        left: 0;
        right: 0;
      }
    }
  }
  &_conter {
    flex-wrap: wrap;
    & > li {
      width: 464px;
      height: 296px;
      margin: 40px 27px 0;
      background-color: #eef2f5;
      border-radius: 10px;
      text-align: center;
      .title {
        color: #000000;
        font-size: 32px;
        padding: 50px 0 30px;
      }
      .text {
        padding: 0 58px;
        color: #999999;
        font-size: 24px;
      }
    }
  }
}
</style>
scoped>
